import React, { useState } from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { blogLinks } from "../utils/links"
import cn from "classnames"
import { localizePath } from "../utils/helpers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"

const NavbarBlog = ({ category, locale, categories }) => {
  const [showNavbar, setShowNavbar] = useState(false)
  return (
    <nav className="blog-header border-bottom bg-white sticky-top">
      <div className="container">
        <div className="d-flex align-items-center">
          <Link
            className="d-block text-uppercase mr-auto font-weight-bold py-3 text-body"
            activeClassName="text-dark font-weight-bold"
            to={localizePath("/blog", locale)}
          >
            moberries blog
          </Link>
          <a
            className="mobile-toggle d-inline-block d-lg-none text-blog"
            data-toggle="collapse"
            href="#mobileBlogNav"
            role="button"
            aria-expanded="false"
            aria-controls="mobileBlogNav"
            onClick={() => setShowNavbar(!showNavbar)}
          >
            <FontAwesomeIcon icon={faBars} />
          </a>

          <ul className="blog-nav nav d-none d-lg-flex">
            {blogLinks[locale].map((l, i) => (
              <li key={i} className="nav-item">
                <Link
                  className={cn("nav-link py-3", {
                    active: category === l.label,
                  })}
                  activeClassName="active nav-link py-3"
                  to={localizePath(l.url, locale)}
                >
                  {l.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div
        className={cn("collapse border-top", {
          show: showNavbar,
        })}
        id="mobileBlogNav"
      >
        <ul className="nav flex-column">
          {blogLinks[locale].map((l, i) => (
            <li key={i} className="nav-item">
              <Link
                className={cn("nav-link py-3", {
                  active: category === l.label,
                })}
                activeClassName="active nav-link py-3"
                to={localizePath(l.url, locale)}
              >
                {l.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query Categories {
        allPrismicCategory {
          nodes {
            data {
              category
            }
            uid
            lang
          }
        }
      }
    `}
    render={data => (
      <NavbarBlog categories={data.allPrismicCategory.nodes} {...props} />
    )}
  />
)
