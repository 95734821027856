import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import BlogFeed from "../components/blog-feed"
import NavbarBlog from "../components/navbar-blog"
import BlogPagination from "../components/blog-pagination"
import { ITEMS_PER_PAGE_FEED } from "../utils/constants"
import { getPath } from "../utils/gatsby-node-helpers"

export const query = graphql`
  query BlogQuery($lang: String, $limit: Int, $skip: Int) {
    prismicBlogHome(lang: { eq: $lang }) {
      data {
        continue_reading_button
        description_meta
        image_meta {
          url
        }
        keywords_meta
        subscribe_button
        subscribe_image {
          url
        }
        subscribe_text
        subscribe_title
        title_meta
      }
    }
    allPrismicBlogPost(
      filter: { lang: { eq: $lang } }
      sort: { fields: first_publication_date, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      totalCount
      nodes {
        data {
          category {
            uid
          }
          cover {
            url
          }
          title {
            text
          }
          title_meta
          body {
            ... on PrismicBlogPostBodyContentBlog {
              primary {
                content_blog {
                  html
                  raw
                  text
                }
              }
              slice_type
            }
          }
        }
        first_publication_date(locale: $lang)
        uid
        lang
      }
    }
  }
`

const BlogTemplate = ({ data, pageContext, location }) => {
  const { lang: locale, page, totalCount } = pageContext
  const maxPage = Math.ceil(totalCount / ITEMS_PER_PAGE_FEED)
  const home = data.prismicBlogHome.data
  const feed = data.allPrismicBlogPost.nodes
  if (!home || !feed) return null

  const { image_meta, keywords_meta, title_meta, description_meta } = home

  return (
    <Layout
      metadata={{
        title: title_meta,
        description: description_meta,
        keywords: keywords_meta,
        image: image_meta && image_meta.url,
      }}
      mainPage="companies"
      locale={locale}
      path={location && location.pathname}
    >
      <NavbarBlog locale={locale} category={null} />
      <main className="blog pt-6 pt-md-7">
        <div className="container">
          <h3 className="section-title mb-4">
            <div className="d-flex align-items-end">
              <div className="flex-grow-1">All Posts</div>
              <small className="text-muted font-weight-light">
                {totalCount} Post{totalCount !== 1 ? "s" : ""}
              </small>
            </div>
          </h3>
          <BlogFeed posts={feed} locale={locale} />
          <BlogPagination
            currentPage={page}
            maxPage={maxPage}
            pathname={getPath({
              docType: "blog_posts_pagination",
              uid: "",
              lang: locale,
            })}
          />
        </div>
      </main>
    </Layout>
  )
}
export default BlogTemplate
